import { createApp } from 'vue';
import App from './App.vue';
import router from './router'
import "./css/common.scss";
import { Swipe, SwipeItem, Image as VanImage } from 'vant';
import 'vant/lib/index.css';
// import Swiper from "swiper";
import "swiper/swiper-bundle.css";
const app = createApp(App);

app.use(router);
app.use(Swipe);
app.use(SwipeItem);
app.use(VanImage);
router.isReady().then(() => {
  app.mount('#app')
})
