<template>
  <template v-if="data.isWeixin">
    <router-view></router-view>
  </template>
  <template v-else>
    <div class="weixinWrap">
      <img class="alertIcon" src="./images/alertIcon.png" alt="">
      <div>请在微信客户端打开</div>
    </div>
  </template>
 
</template>

<script setup>
 import { onMounted,reactive } from 'vue';
 import wx from 'weixin-js-sdk';
 import requst from "./js/request";
 let data=reactive({
  isWeixin:true,//是否是微信环境
 });
  let initScale=()=>{
    var scale = 750/100;
    var doc = window.document;
    var docEl = doc.documentElement;
    var clientWidth = docEl.clientWidth||window.innerWidth;
    /* 页面宽度大于 设计图文档宽度 时不再放大,小于320时不再缩小 */
    if(clientWidth>750){
      clientWidth=750;
    }
    if(clientWidth<320){
      clientWidth=320;
    }
    var oldSize = (clientWidth/scale*2) + 'px';
    docEl.style.fontSize = oldSize;
  }
  //判断微信环境
  let judgeWeixin =()=>{
    var ua = window.navigator.userAgent.toLowerCase();
    if (ua.match(/MicroMessenger/i) == 'micromessenger') {
      data.isWeixin = true;
      weixinOperate();
    }else{
      data.isWeixin = false;
    }
  }
  //微信操作
  let weixinOperate = ()=>{
    wx.ready(function () { 
      wx.updateAppMessageShareData({ 
        title: '四十如新，步履不停', // 分享标题
        desc:'如新集团40周年，让我们一起欢庆。',
        link: window.location.href, // 分享链接，该链接域名或路径必须与当前页面对应的公众号JS安全域名一致
        imgUrl:'https://yunyuan-oss.oss-cn-hangzhou.aliyuncs.com/ruxin/share.png', // 分享图标
        success: function () {
          // 设置成功
        }
      })
      wx.updateTimelineShareData({
        title: '四十如新，步履不停', // 分享标题
        desc:'如新集团40周年，让我们一起欢庆。',
        link: window.location.href, // 分享链接，该链接域名或路径必须与当前页面对应的公众号JS安全域名一致
        imgUrl:'https://yunyuan-oss.oss-cn-hangzhou.aliyuncs.com/ruxin/share.png', // 分享图标
        success: function() {
          // 设置成功
          console.log('分享到朋友圈')
        }
      })
    })
    wx.error(function(res) {
      console.log('注册', res)
    });
    requst({
      url:'https://nuskin40anniversary.innatech.com.cn/WX_WeixinWS/registerWxJs.wx',
      data:{
        outkey: 'wx17c70594bdc8badf',
        url: window.location.href
      },
      method:'post',
    }).then((res) => {
      console.log(res);
      if (res&&res.content) {
        let content = res.content;
        wx.config({
          debug: false, // 开启调试模式,调用的所有api的返回值会在客户端alert出来，若要查看传入的参数，可以在pc端打开，参数信息会通过log打出，仅在pc端时才会打印。
          appId: content.appid, // 必填，公众号的唯一标识
          timestamp: content.timestamp, // 必填，生成签名的时间戳
          nonceStr: content.noncestr, // 必填，生成签名的随机串
          signature: content.signature, // 必填，签名
          jsApiList: ['updateAppMessageShareData', 'updateTimelineShareData'] // 必填，需要使用的JS接口列表
        });
      } else {
        console.log('业务错误', res)
      }
    })
    .catch((err) => {
      console.log('请求出错', err)
    })
  }

  onMounted(()=>{
    initScale();
    judgeWeixin();
    window.addEventListener('resize',()=>{
      initScale();
    })
  })
</script>

<style lang="scss">
@import url('./css/common.scss');
#app{
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}
.wrap{
  width: 100%;
  height: 100%;
  overflow: hidden;
  position: relative;
  .bg{
    width: 100%;
    position: absolute;
    top: 0;
    left: 0;
    z-index: -1;
  }

}
</style>
