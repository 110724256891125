import axios from 'axios';
import qs from "qs";
axios.defaults.timeout = 10000000;
axios.defaults.headers.common['Cache-Control'] = 'no-cache';
axios.interceptors.request.use(config => {
    if (config.headType == 'json') {
      config.headers['Content-Type'] = 'application/json'
    } else if (config.headType) {
      config.headers['Content-Type'] = 'application/x-www-form-urlencoded;Charset=UTF-8'
    } else {
      delete config.headers['Content-Type']
    }
		if(config.method.toLowerCase() === 'post') {
			if (!config.noJson) {
        config.data = config.headType === "json" ? config.data : qs.stringify(config.data)
			}
		}
		if (config.method.toLowerCase() === 'get') {
      const data = qs.stringify(config.data);
      let url = config.url;
      config.url = url + `?${data}`
		}
    return config
}, error => {
  return Promise.reject(error)
})

export default function request(option) {
  if(!option.data) {
    option.data = {};
  }
  return axios(option).then(res => res.data).then(res => {
    res.status =  res.code == 200? 1 : 0;
    return res;
  }).catch(res => {
    console.log(res);
    return {
      status: 0
    }
  })
}