import { createRouter, createWebHashHistory } from 'vue-router';
let routes = [
  {
    path: '/',
    redirect: '/index'
  },
  {
    path: '/index',
    name: 'index',
    component: () => import('@/pages/index.vue')
  },
  {
    path: '/brandStory',
    name: 'brandStory',
    component: () => import('@/pages/brandStory.vue')
  },
  {
    path: '/techno',
    name: 'techno',
    component: () => import('@/pages/techno.vue')
  },
  {
    path: '/goodPower',
    name: 'goodPower',
    component: () => import('@/pages/goodPower.vue')
  },
  {
    path: '/starProduct',
    name: 'starProduct',
    component: () => import('@/pages/starProduct.vue')
  },
  {
    path: '/dealer',
    name: 'dealer',
    component: () => import('@/pages/dealer.vue')
  },
  {
    path: '/trmeDetial',
    name: 'trmeDetial',
    component: () => import('@/pages/trme/trmeDetial.vue')
  },
  {
    path: '/trmeMaterial',
    name: 'trmeMaterial',
    component: () => import('@/pages/trme/trmeMaterial.vue')
  },
  {
    path: '/wellspaDetail',
    name: 'wellspaDetail',
    component: () => import('@/pages/wellspa/wellspaDetail.vue')
  },
  {
    path: '/wellspaMaterial',
    name: 'wellspaMaterial',
    component: () => import('@/pages/wellspa/wellspaMaterial.vue')
  },
  {
    path: '/chuangxing',
    name: 'chuangxing',
    component: () => import('@/pages/cxdfy/index.vue')
  },
  {
    path: '/nuStar',
    name: 'nuStar',
    component: () => import('@/pages/cxdfy/nuStar.vue')
  },
  {
    path: '/expo',
    name: 'expo',
    component: () => import('@/pages/cxdfy/expo.vue')
  },
];
const router = createRouter({
  history: createWebHashHistory(),
  routes
})
export default router